import { initialValueProps, IParamsFilterList } from 'src/event-add-can/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialValue: initialValueProps = {
  paramsFilter: {
    eventName: '',
    endDate: null,
    startDate: null,
    skus: [],
  },
  idDelete: 0,
  isOpenFormDelete: false,
  idDuplicate: 0,
  isOpenFormDuplicate: false,
};

const eventAddCanSlice = createSlice({
  name: 'eventAddCan',
  initialState: initialValue,
  reducers: {
    setParamsFilter: (state, action: PayloadAction<IParamsFilterList>) => {
      const { skus, eventName, endDate, startDate } = action.payload;
      state.paramsFilter = {
        eventName: eventName || '',
        skus: skus || [],
        startDate: startDate || null,
        endDate: endDate || null,
      };
    },
    setIdDelete: (state, action: PayloadAction<number>) => {
      state.idDelete = action.payload;
    },
    setIsOpenFormDelete: (state, action: PayloadAction<boolean>) => {
      state.isOpenFormDelete = action.payload;
    },
    setIdDuplicate: (state, action: PayloadAction<number>) => {
      state.idDuplicate = action.payload;
    },
    setIsOpenFormDuplicate: (state, action: PayloadAction<boolean>) => {
      state.isOpenFormDuplicate = action.payload;
    },
  },
});

export default eventAddCanSlice.reducer;

export const {
  setParamsFilter,
  setIdDelete,
  setIsOpenFormDelete,
  setIdDuplicate,
  setIsOpenFormDuplicate,
} = eventAddCanSlice.actions;
