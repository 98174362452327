import { ROOTS_DASHBOARD } from "src/common/routes/paths";

export enum Permission {
  VIEW = 'read',
  FULL = 'full',
  CUSTOM = 'customize',
}

export enum Resource {
  GIFT_MANAGE = '1',
  USER_GIFT_MANAGE = '2',
  ORDER_MANAGE = '3',
  COIN_MANAGE = '4',
  IDENTITY_POINT_HISTORY = '5',
  STORE_ADMIN = '6',
  ADMIN_MANAGE = '7',
  MANAGE_EVENT = '8',
  MANAGE_GROUP_EVENT = '9',
  EVENT_HISTORY_PRIZE = '10',
  EVENT_HISTORY_PRIZE_V2 = '11',
  CAMPAIGN_MANAGE = '12',
  FLOATING_BUTTON_MANAGE = '13',
  BANNER_MANAGE = '14',
  FILTER_MANAGE = '16',
  HIDDEN_TAG_MANAGE = '17',
  WEBAPP_MANAGE = '18',
  WEBVIEW_MANAGE = '19',
  CRM_TRANSACTION_TYPE = '20',
  ACCOUNT_MANAGE = '15',
  REVIEW_MANAGE = '22',
  FEEDBACK_RECOMMEND_TAG_MANAGE = '21',
  HISTORY_EDIT_MANAGE = '124'
}


export enum FileName{
  GIFT_MANAGE = "/dashboard/gifts",
  USER_GIFT_MANAGE = "/dashboard/user-gifts",
  ORDER_MANAGE = "/dashboard/orders",
  COIN_MANAGE = "/dashboard/coins",
  IDENTITY_POINT_HISTORY = "/dashboard/identity-point",
  STORE_ADMIN = "/dashboard/stories",
  ADMIN_MANAGE = "/dashboard/admins",
  MANAGE_EVENT = "/dashboard/event-quarter-one",
  MANAGE_GROUP_EVENT = "/dashboard/event-q1-groupEvent/list",
  EVENT_HISTORY_PRIZE = "/dashboard/event-history-prize/list",
  EVENT_HISTORY_PRIZE_V2 = "/dashboard/event-history-prize-v2/list",
  CAMPAIGN_MANAGE = "/dashboard/campaign-manage/list",
  FLOATING_BUTTON_MANAGE = "/dashboard/floating-button-manage",
  BANNER_MANAGE = "/dashboard/banner-manage/list",
  FILTER_MANAGE = "/dashboard/filters",
  HIDDEN_TAG_MANAGE ="/dashboard/hiddenTags",
  WEBAPP_MANAGE = "/dashboard/webapp-manage",
  WEBVIEW_MANAGE = "/dashboard/webview-manage/list",
  CRM_TRANSACTION_TYPE = "/dashboard/crm-transaction-type/list",
  ACCOUNT_MANAGE = "/dashboard/accounts/users/list",
  REVIEW_MANAGE = "/dashboard/review-report/list",
  FEEDBACK_RECOMMEND_TAG_MANAGE = "/dashboard/feedback-recommend-tag/list",
  HISTORY_EDIT_MANAGE = "/dashboard/admins-edit-history"

}