import { Box } from '@mui/material';

const HiddenTagNote = () => {
  return (
    <Box>
      <Box>Lưu ý:</Box>
      <ul style={{ paddingLeft: 50 }}>
        <li>Không có giới hạn về việc tạo tag.</li>
        <li>
          Mỗi quà sẽ được gắn tối đa 5 tag. Sau khi tạo tag thành công, admin vào quản lý
          quà để thực hiện việc gắn tag cho quà.
        </li>
        <li>
          Khi user thao tác trên quà (xem quà, đổi quà, đặt trước, tích xu) thì hệ thống
          sẽ cộng điểm vào user tag tương ứng.
          <ul style={{ paddingLeft: 50 }}>
            <li>
              Đối với user tag brand user tích xu: lấy những quà thuộc user tag brand cao
              điểm nhất để hiển thị ở Dành riêng cho bạn. Lưu ý: user tag brand phải trùng
              với brand lưu trong hệ thống.
            </li>
            <li>
              Đối với user tag còn lại: lấy những quà thuộc 3 user tag cao điểm nhất để
              hiển thị ở Dành riêng cho bạn.
            </li>
          </ul>
        </li>
      </ul>
    </Box>
  );
};

export default HiddenTagNote;
