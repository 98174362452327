import { useState, useEffect } from 'react';
import { List, Box } from '@mui/material';
import { NavSectionProps } from '../type';
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import useLocales from 'src/common/hooks/useLocales';
import { useGetMenu } from '../hook/useGetMenu';
import { MenuItem } from '../interface';

export default function NavSectionVertical({
  navConfig,
  isCollapse,
  ...other
}: NavSectionProps) {
  const { translate } = useLocales();
  const { data, isLoading, error } = useGetMenu(); // Kiểm tra trạng thái API
  const [menuData, setMenuData] = useState<MenuItem[]>([]); 

  // Cập nhật menu khi API có dữ liệu mới
  useEffect(() => {
    if (data && Array.isArray(data)) {
      setMenuData(data);
    }
  }, [data]);

  // Kiểm tra nếu API lỗi hoặc đang tải
  if (isLoading) {
    return <Box sx={{ textAlign: 'center', py: 2 }}>Đang tải menu...</Box>;
  }
  if (error) {
    return <Box sx={{ textAlign: 'center', py: 2, color: 'red' }}>Lỗi tải menu!</Box>;
  }

  // Lọc menu dựa trên dữ liệu từ API
  const menuIds = new Set(menuData?.map((item) => item.id?.toString()));

  const filteredNavConfigWithData = navConfig
    .map((group) => ({
      ...group,
      items: group.items.filter((item) => {
        // Nếu item không có permission và resource thì hiển thị luôn
        if (!item.permission && !item.resource) {
          return true;
        }
        const itemResource = item.resource ? String(item.resource) : '';
        return menuIds.has(itemResource);
      }),
    }))
    .filter((group) => group.items.length > 0);

  return (
    <Box {...other}>
      {filteredNavConfigWithData.length > 0 ? (
        filteredNavConfigWithData.map((group) => (
          <List key={group.subheader} disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle sx={{ ...(isCollapse && { opacity: 0 }) }}>
              {translate(group.subheader)}
            </ListSubheaderStyle>

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChildren={!!list.children}
                isCollapse={isCollapse}
              />
            ))}
          </List>
        ))
      ) : (
        <Box sx={{ textAlign: 'center', py: 2, color: 'gray' }}>
          Không có menu phù hợp.
        </Box>
      )}
    </Box>
  );
}
