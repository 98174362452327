import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import Iconify from 'src/common/components/Iconify';
import Scrollbar from 'src/common/components/Scrollbar';
import { TableNoData } from 'src/common/components/table';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import useTable from 'src/common/hooks/useTable';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import HistoryEditAminToolbar, {
  FormData,
} from './components/HistoryEditAminToolbarProps';
import { TABLE_HEAD_HISTORY_ADMIN } from './constants';
import TableHeadCustom from './components/table/TableHeadCustom';
import { AdminEditHistoryListRow } from './components/table/AdminEditHistoryListRow';
import { IEditAdminParams, IFormAdminEditHistory } from '../interfaces';
import { useState } from 'react';
import { useGetHistoryAdminEdit } from '../hooks/useGetHistoryEditAdmin';
import { useExportData } from '../hooks/useExportData';
import dayjs from 'dayjs';
import useMessage from 'src/store-admin/hooks/useMessage';
import { FORTMAT_DATE } from '../constants';
const ListHistoryEditAmin = () => {
  const navigate = useNavigate();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultRowsPerPage: 5, // Giá trị mặc định cho rowsPerPage
  });

  const { mutate } = useExportData();
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() + 1);

  const formatDate = (date: Date) => date.toISOString().split('T')[0];

  // Thêm state để kiểm soát việc đã search hay chưa
  const [isSearched, setIsSearched] = useState(false);
    const { showSuccessSnackbar, showErrorSnackbar } = useMessage();
  // Đồng bộ params với page và rowsPerPage từ useTable
  const [params, setParams] = useState<IEditAdminParams>({
    startDate: '',
    endDate: '',
    page: page + 1, // API thường bắt đầu từ 1 thay vì 0
    limit: rowsPerPage,
    searchText: '',
  });

  const { data: dataFromAPI, isLoading } = useGetHistoryAdminEdit(params);
  const dataHistory = isSearched && dataFromAPI?.response ? dataFromAPI.response : [];
  const totalRecords = isSearched && dataFromAPI?.pagination?.totalRecords ? dataFromAPI.pagination.totalRecords : 0;

  const handleSearch = (searchParams: FormData) => {
    const startDate = searchParams.startDate
      ? dayjs(new Date(searchParams.startDate)).format(FORTMAT_DATE)
      : formatDate(today);
    const endDate = searchParams.endDate
      ? dayjs(new Date(searchParams.endDate)).format(FORTMAT_DATE)
      : formatDate(sevenDaysAgo);

    setParams((prev) => ({
      ...prev,
      startDate,
      endDate,
      searchText: searchParams.searchText || '',
      page: 1, // Reset về trang 1 khi search
    }));
    setPage(0); // Đồng bộ với useTable
    setIsSearched(true); // Đánh dấu đã search
  };

  const handleReset = () => {
    setParams({
      startDate: '',
      endDate: '',
      page: 1,
      limit: rowsPerPage,
      searchText: '',
    });
    setPage(0);
    setIsSearched(false); // Reset về trạng thái chưa search
  };

  // Chỉ hiển thị "No Data" khi đã search và không có dữ liệu
  const isNotFound = isSearched && !dataHistory.length && !isLoading;

  return (
    <>
      <HeaderBreadcrumbs
        heading={BREADCUMBS.LIST_ADMIN_EDIT_HISTORY}
        links={[
          { name: BREADCUMBS.MANAGE_ADMIN, href: PATH_DASHBOARD.edit_admin.list },
          { name: BREADCUMBS.LIST_ADMIN_EDIT_HISTORY },
        ]}
        action={
          <Button
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            onClick={() => {
              mutate({
                startDate: params.startDate,
                endDate: params.endDate,
                page: page,
                limit: rowsPerPage,
                searchText: params.searchText,
              });
            }}
          >
            Export
          </Button>
        }
      />
      <Card sx={{ bgcolor: 'white' }}>
        <Box sx={{ p: 1 }}>
          <HistoryEditAminToolbar onSearch={handleSearch} onReset={handleReset} />
        </Box>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                headLabel={TABLE_HEAD_HISTORY_ADMIN}
                rowCount={dataHistory.length}
              />
              <TableBody>
                {dataHistory.map((row: IFormAdminEditHistory) => (
                  <AdminEditHistoryListRow
                    key={row.id}
                    row={row}
                    selected={false}
                    onSelectRow={() => {}}
                    onDeleteRow={() => {}}
                    onEditRow={() => {}}
                  />
                ))}
                {isSearched && <TableNoData isNotFound={isNotFound} />}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, newPage) => {
                onChangePage(e, newPage);
                setParams((prev) => ({
                  ...prev,
                  page: newPage + 1,
                }));
              }}
              onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newRowsPerPage = parseInt(event.target.value, 10);
                onChangeRowsPerPage(event);
                setParams((prev) => ({
                  ...prev,
                  limit: newRowsPerPage,
                  page: 1, // Reset về trang 1 khi đổi rowsPerPage
                }));
                setPage(0);
              }}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Thu gọn"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Scrollbar>
      </Card>
    </>
  );
};

export default ListHistoryEditAmin;