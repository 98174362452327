import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { getMenu } from '../service';


export function useGetMenu() {
  return {
    ...useQuery([QUERY_KEYS.MENU_LIST], () => getMenu(), {
      select: (data) => {
        console.log("data", data);
        return data.data.response || [];
      },
    }),
  };
}
