import dayjs from 'dayjs';
import { FORMAT_DATE_TIME } from './common.constants';

export function toQueryString(objParams: Object) {
  const str = [];
  for (const p in objParams) {
    if (
      Object.prototype.hasOwnProperty.call(objParams, p) &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objParams[p] + ''
    ) {
      str.push(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `${encodeURIComponent(p)}=${encodeURIComponent(objParams[p])}`
      );
    }
  }

  return str.join('&');
}

export function toUrl(url: string, query: object) {
  return `${url}?${toQueryString(query)}`;
}

export function getMessError(message: string | string[]) {
  if (typeof message === 'string') {
    return message;
  }
  return message[0] || 'Lỗi không xác định!';
}

export function getUniqueArray(originArray: (string | number)[]) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [...new Set(originArray)];
}

export function formatDate(date?: string, format?: string) {
  return dayjs(date).format(format || 'DD/MM/YYYY HH:mm');
}

export function formatDateEditHistory(date?: string, format?: string) {
  return dayjs(date).format(format || FORMAT_DATE_TIME);
}

export function formatDateNoTime(date?: string, format?: string) {
  return dayjs(date).format(format || 'DD/MM/YYYY');
}

export function formatDateNew(date?: string, format?: string) {
  return dayjs(date).format(format || 'MM/DD/YYYY HH:mm');
}

export function formatDateTime(input: string): string {
  const parts = input.split('/');

  if (parts.length === 3) {
    const [day, month, year] = parts;
    const formattedTime = `${month}/${day}/${year}`;
    return formattedTime;
  } else {
    throw new Error('Định dạng thời gian không hợp lệ');
  }
}

export function isValidURL(url: string) {
  const pattern = new RegExp(
    '^https:\\/\\/' + // must start with https://
      '([a-z\\d]([a-z\\d-]*[a-z\\d])*(\\.[a-z]{2,})+|' + // domain name
      '(\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(url);
}

export function sortImagesByOrder(urls: string[]): string[] {
  return urls.sort((a, b) => {
    const orderA = parseInt(getOrderFromUrl(a), 10);
    const orderB = parseInt(getOrderFromUrl(b), 10);
    return orderA - orderB;
  });
}

function getOrderFromUrl(url: string): string {
  const match = url.match(/order=(\d+)/);
  return match ? match[1] : '0'; // Trả về '0' nếu không tìm thấy order
}

export function replaceOrderInUrl(url: string, newOrder: number): string {
  // Tìm và thay thế bất kỳ giá trị nào của 'order' bằng newOrder
  const regex = /([?&]order=)\d+/;
  return url.replace(regex, `$1${newOrder}`);
}

export function getUrlFromFile(file: any) {
  if (!file) return null;

  const domain = 'https://storage.googleapis.com';
  const bucket = file.bucket;
  const name = file.name;
  return `${domain}/${bucket}/${name}`;
}
