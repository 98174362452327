import { UserTierCode } from './enums';
import { IParamsGetUserList } from './interface';

export const DEFAULT_VALUE_PARAMS_GET_USER_LIST: IParamsGetUserList = {
  search: '',
  tierCode: undefined,
};

export const TABLE_HEAD_CUSTOMER_LIST = [
  { id: 'id', label: 'ID', align: 'left' },
  { id: 'blocked_account', label: 'Trạng thái đăng nhập', align: 'left' },
  { id: 'name', label: 'Tên khách hàng', align: 'left' },
  { id: 'tier_code', label: 'Hạng thành viên', align: 'left' },
  { id: 'phone_number', label: 'SĐT', align: 'left' },
  { id: 'gift_point', label: 'Xu tích lũy', align: 'left' },
  { id: 'number_of_blocked_scan_sbps', label: 'Số lần khóa tích xu SBPS', align: 'left' },
  { id: 'number_of_blocked_scan', label: 'Số lần khóa tích xu SB', align: 'left' },
  { id: 'number_of_blocked_login', label: 'Số lần khóa đăng nhập', align: 'left' },
  { id: 'full_address', label: 'Địa chỉ mặc định', align: 'left' },
  { id: 'block_update_address', label: 'Khóa địa chỉ mặc định', align: 'left' },
];

export const TIER_CODE_OPTONS = [
  { label: 'MEMBER', value: UserTierCode.MEMBER },
  { label: 'TITAN', value: UserTierCode.TITAN },
  { label: 'GOLD', value: UserTierCode.GOLD },
  { label: 'PLATINUM', value: UserTierCode.PLATINUM },
];

export const LIMIT_NUMBER_OF_BLOCK_ACCOUNT = 3;
