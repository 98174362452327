import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialValue = {
  idDelete: 0,
  isOpenFormDelete: false,
};

const eventPopupSlice = createSlice({
  name: 'eventAddCan',
  initialState: initialValue,
  reducers: {
    setIdDelete: (state, action: PayloadAction<number>) => {
      state.idDelete = action.payload;
    },
    setIsOpenFormDelete: (state, action: PayloadAction<boolean>) => {
      state.isOpenFormDelete = action.payload;
    },
  },
});

export default eventPopupSlice.reducer;

export const { setIdDelete, setIsOpenFormDelete } = eventPopupSlice.actions;
