import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IInitialValueFilter,
  IParamsIdentityPointList,
  IParamsUserHistoryPointList,
} from './interface';
import dayjs from 'dayjs';

const initialValue: IInitialValueFilter = {
  paramsUserHistoryPointList: {
    customerId: '',
    actionType: undefined,
    startDate: dayjs().startOf('month').toISOString(),
    endDate: dayjs().endOf('month').toISOString(),
    isAll: false,
  },
  paramsIdentityPointList: {
    customerId: '',
    actionType: undefined,
    startDate: dayjs().startOf('month').toISOString(),
    endDate: dayjs().endOf('month').toISOString(),
    isAll: false,
  },
};

const identityPointSlice = createSlice({
  name: 'identityPoint',
  initialState: initialValue,
  reducers: {
    setParamsUserHistoryPointList: (
      state,
      action: PayloadAction<IParamsUserHistoryPointList>
    ) => {
      const { customerId, actionType, endDate, startDate, isAll } = action.payload;
      state.paramsUserHistoryPointList = {
        customerId: customerId || '',
        actionType: actionType || undefined,
        startDate: startDate,
        endDate: endDate,
        isAll: isAll,
      };
    },
    setParamsIdentityPointList: (
      state,
      action: PayloadAction<IParamsIdentityPointList>
    ) => {
      const { customerId, actionType, endDate, startDate, isAll } = action.payload;
      state.paramsIdentityPointList = {
        customerId: customerId || '',
        actionType: actionType || undefined,
        startDate: startDate,
        endDate: endDate,
        isAll: isAll || false,
      };
    },
  },
});

export default identityPointSlice.reducer;

export const { setParamsUserHistoryPointList, setParamsIdentityPointList } =
  identityPointSlice.actions;
