import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFeedbackDetail, IParamsFeedbackAll } from './interface';
import { RootState } from 'src/common/redux/store';

const initialValue: {
  paramsChart: IParamsFeedbackAll;
  feedback: IFeedbackDetail;
} = {
  paramsChart: {},
  feedback: {
    id: 0,
    phoneNumber: '',
    lastShown: null,
    action: '',
    starCount: 1,
    feedback: null,
    tags: [],
  },
};

const feedbackReviewManageSlice = createSlice({
  name: 'feedbackReviewManage',
  initialState: initialValue,
  reducers: {
    setParamsChart: (state, action: PayloadAction<IParamsFeedbackAll>) => {
      state.paramsChart = action.payload;
    },
    setFeedback: (state, action: PayloadAction<IFeedbackDetail>) => {
      state.feedback = action.payload;
    },
  },
});

export default feedbackReviewManageSlice.reducer;
export const feedbackSelector = (state: RootState) =>
  state.feedbackReviewManageReducer.feedback;
export const { setParamsChart, setFeedback } = feedbackReviewManageSlice.actions;
