export const TABLE_HEAD_HISTORY_ADMIN = [
    // { id: 'checkBox', label: '', align: 'left' },
    { id: 'id', label: 'ID', align: 'left' },
    {
      id: 'time',
      label: 'Thời gian',
      align: 'left',
    },
    {
      id: 'admin',
      label: 'Admin',
      align: 'left',
    },
    {
      id: 'action',
      label: 'Hành động',
      align: 'left',
    },
    {
      id: 'menu',
      label: 'Menu',
      align: 'left',
    },
    {
      id: 'namerecord',
      label: 'Tên record đã chỉnh sửa',
      align: 'left',
    },
    
  ];