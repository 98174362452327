import { Checkbox, MenuItem, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import Iconify from 'src/common/components/Iconify';
import { TableMoreMenu } from 'src/common/components/table';
import Can from 'src/common/lib/Can';
import { IPropsAdminEditHistoryTableRow, IPropsAdminTableRow } from 'src/admin/interfaces'; 
import dayjs from "dayjs";
import { formatDateEditHistory } from 'src/common/constants/common.utils';
function AdminEditHistoryListRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: IPropsAdminEditHistoryTableRow) {
  const {createdAt,adminName,adminAction,recordIdentity, id,adminMenu } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (category: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(category.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      
      <TableCell align="left">{id}</TableCell>

      <TableCell align="left">{formatDateEditHistory(createdAt)}</TableCell>

      <TableCell align="left">{adminName}</TableCell>

      <TableCell align="left">{adminAction}</TableCell>
      <TableCell align="left">{adminMenu}</TableCell>

      <TableCell align="left">{recordIdentity}</TableCell>
      
     
      
    </TableRow>
  );
}

export { AdminEditHistoryListRow };
