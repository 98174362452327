import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import Iconify from 'src/common/components/Iconify';
import { LoadingButton } from '@mui/lab';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { FORMAT_DATE_NEWS } from 'src/store-admin/constants';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import dayjs from 'dayjs';
import { formatDate } from 'src/common/constants/common.utils';

export interface FormData {
  searchText: string;
  startDate: string | null;
  endDate: string | null;
}

const formatDateToString = (date: Date | null): string | null => {
  if (!date) return null;

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

interface HistoryEditAminToolbarProps {
  onSearch: (data: FormData) => void; // Hàm nhận dữ liệu tìm kiếm từ form
  onReset: () => void;
}

const HistoryEditAminToolbar = ({ onSearch, onReset }: HistoryEditAminToolbarProps) => {
  const methods = useForm<FormData>({
    defaultValues: {
      searchText: '',
      startDate: null,
      endDate: null,
    },
  });
  const { control, handleSubmit, reset } = methods;

  const onSubmit = (data: FormData) => {
    const formattedData: FormData = {
      searchText: data.searchText,
      startDate: data.startDate ? formatDate(data.startDate) : null,
      endDate: data.endDate ? formatDate(data.endDate) : null,
    };
    console.log('Formatted data:', formattedData);

    onSearch(data);
  };
  const handleReset = () => {
    reset({
      searchText: '',
      startDate: null,
      endDate: null,
    });
    onSearch({ searchText: '', startDate: null, endDate: null }); // Gọi lại hàm tìm kiếm với giá trị mặc định
    onReset(); // Gọi lại hàm reset từ props
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={3} py="30px">
          <Grid item xs={5}>
            <Controller
              name="searchText"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  placeholder="Tìm kiếm theo tên"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: 'text.disabled', width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              name="startDate"
              key={'startDate'}
              control={control}
              render={({ field }) => (
                <MobileDateTimePicker
                  {...field}
                  label="Start date"
                  key={'startDate'}
                  inputFormat={FORMAT_DATE_NEWS}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Calendar />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              name="endDate"
              key={'endDate'}
              control={control}
              render={({ field }) => (
                <MobileDateTimePicker
                  {...field}
                  label="End date"
                  key={'endDate'}
                  inputFormat={FORMAT_DATE_NEWS}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Calendar />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" spacing={1} marginTop="5px" mx={1}>
              <LoadingButton type="submit" variant="contained" size="large">
                Tìm kiếm
              </LoadingButton>
              <Button
                onClick={handleReset}
                variant="contained"
                color="inherit"
                size="large"
              >
                Hủy bỏ
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default HistoryEditAminToolbar;
