import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { IAdminParams, IEditAdminParams, IResAdmins } from '../interfaces';
import { getHistory } from '../services';


export function useGetHistoryAdminEdit(params: IEditAdminParams) {
  return {
    ...useQuery([QUERY_KEYS.ADMIN, params], () => getHistory(params), {
      select: (data) => data.data,
      enabled: !!params,
    }),
  };
}
