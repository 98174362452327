import {
  API_BRAND_GOTIT_V2,
  API_CATEGORY_FILTER_ADMIN_V2,
  API_CATEGORY_GOTIT_V2,
  API_COURSES_BKID_V2,
  API_EXPORT_GIFT,
  API_FILTER_TAGS_ADMIN_V3,
  API_GIFT_CATEGORIES_ADMIN_V3,
  API_GIFT_PRE_ORDERS_ADMIN_V3,
  API_GIFT_UPDATE_QUANTITY_V4,
  API_GIFT_UPDATE_STATUS_V4,
  API_ADMIN_GIFT_V4,
  API_LIST_BADGES_V2,
  API_LIST_DYNAMIC_FILTER_V2,
  API_PRODUCT_GOTIT_V2,
  API_USERS_ADMIN_V3,
} from 'src/common/constants/apis';
import {
  CommonResponseAPI,
  CommonResponseV4API,
  DataResponseList,
} from 'src/common/constants/common.interfaces';
import axiosInstance from 'src/common/utils/axios';
import {
  IGift,
  IGiftCategoryCreateDto,
  IGiftCategoryEditDto,
  IGiftCreateDto,
  IGiftEditDto,
  IGiftUpdateQuantity,
  IParamsDeleteGift,
  IParamsGiftList,
  IResGiftById,
  IResGiftCategoryCreate,
} from './interface';

export const createGift = (data: IGiftCreateDto) => {
  return axiosInstance.post<any, any>(`${API_ADMIN_GIFT_V4}`, data);
};

export const getListGift = (params: IParamsGiftList) => {
  return axiosInstance.get<
    any,
    CommonResponseAPI<CommonResponseV4API<DataResponseList<IGift[]>>>
  >(`${API_ADMIN_GIFT_V4}`, {
    params,
  });
};

export const deleteGift = (params: IParamsDeleteGift) => {
  return axiosInstance.delete(`${API_ADMIN_GIFT_V4}/delete`, { params });
};

export const getGiftById = async (id: number) => {
  const res = await axiosInstance.get<any, CommonResponseAPI<IResGiftById>>(
    `${API_ADMIN_GIFT_V4}/${id}`
  );
  return res.data.response;
};

export const editGift = async (giftId: number, data: IGiftEditDto) => {
  const res = await axiosInstance.patch(`${API_ADMIN_GIFT_V4}/${giftId}`, data);
  return res.data;
};

export const updateGiftStatus = async (giftId: number, status: string) => {
  const res = await axiosInstance.patch(`${API_GIFT_UPDATE_STATUS_V4}/${giftId}`, {
    status,
  });
  return res.data;
};

export const updateGiftQuantity = async (giftId: number, data: IGiftUpdateQuantity) => {
  const res = await axiosInstance.patch(`${API_GIFT_UPDATE_QUANTITY_V4}/${giftId}`, data);
  return res.data;
};

export const getCategories = () => {
  return axiosInstance.get(`${API_CATEGORY_FILTER_ADMIN_V2}`, {
    params: { size: 200 },
  });
};

export const getGotItBrands = () => {
  return axiosInstance.get(`${API_BRAND_GOTIT_V2}`);
};

export const getGotItCategories = () => {
  return axiosInstance.get(`${API_CATEGORY_GOTIT_V2}`);
};

export const getGotItProducts = (params: any) => {
  return axiosInstance.get(`${API_PRODUCT_GOTIT_V2}`, { params });
};

export const getCoursesBkids = () => {
  return axiosInstance.get(`${API_COURSES_BKID_V2}`);
};

export const getBadges = () => {
  return axiosInstance.get(`${API_LIST_BADGES_V2}`);
};

export const getDynamicFilters = () => {
  return axiosInstance.get(`${API_LIST_DYNAMIC_FILTER_V2}`);
};

export const getGiftCategoriesV3 = (params: any) => {
  return axiosInstance.get(`${API_GIFT_CATEGORIES_ADMIN_V3}`, {
    params,
  });
};

export const getGiftPreOrdersV3 = (params: any) => {
  return axiosInstance.get(`${API_GIFT_PRE_ORDERS_ADMIN_V3}`, {
    params,
  });
};

export const getUsers = (params: any) => {
  return axiosInstance.get(`${API_USERS_ADMIN_V3}`, {
    params,
  });
};

export const createGiftCategory = async (data: IGiftCategoryCreateDto) => {
  const res = await axiosInstance.post<any, IResGiftCategoryCreate>(
    `${API_GIFT_CATEGORIES_ADMIN_V3}`,
    data
  );
  return res.data;
};

export const editGiftCategory = async (
  categoryId: string,
  data: IGiftCategoryEditDto
) => {
  const res = await axiosInstance.patch(
    `${API_GIFT_CATEGORIES_ADMIN_V3}/${categoryId}`,
    data
  );
  return res.data;
};

export const getGiftCategoryByIdV3 = async (id: string) => {
  const res = await axiosInstance.get(`${API_GIFT_CATEGORIES_ADMIN_V3}/${id}`);
  return res.data.response.data;
};

export const getFilterDetail = async (id: string) => {
  const res = await axiosInstance.get(`${API_FILTER_TAGS_ADMIN_V3}/${id}`);
  return res.data.response.data;
};

export const exportGiftList = (params: any) => {
  return axiosInstance.post(`${API_EXPORT_GIFT}`, params, {
    responseType: 'blob',
  });
};
