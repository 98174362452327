import {
  ENUM_GENDER,
  ENUM_RANK,
  ENUM_SCHEDULE_NOTIFICATION,
  ENUM_STATUS,
  ENUM_TYPE_BIRTH,
  ENUM_TYPE_NOTIFICATION,
  ENUM_TYPE_SENT,
  ICampaignByIdForm,
  ICampaignListFilterSubmit,
} from './interface';

export const TABLE_HEAD_CAMPAIGN_LIST = [
  { id: 'id', label: 'ID', align: 'left' },
  {
    id: 'name',
    label: 'Tên chiến dịch',
    align: 'left',
  },
  {
    id: 'typeCondition',
    label: 'Loại điều kiện',
    align: 'center',
  },
  {
    id: 'status',
    label: 'Trạng thái',
    align: 'center',
  },
  {
    id: 'option',
    label: 'Tùy chọn',
    align: 'center',
  },
];

export const DEFAULT_VALUE_FILTER: ICampaignListFilterSubmit = {
  searchText: '',
  condition: null,
};

export const TYPE_NOTIFICATION = [
  {
    id: ENUM_TYPE_NOTIFICATION.ADD_POINT,
    name: 'Thông báo tích điểm',
  },
  {
    id: ENUM_TYPE_NOTIFICATION.SPEND_POINT,
    name: 'Thông báo đổi quà',
  },
  { id: ENUM_TYPE_NOTIFICATION.RANK, name: 'Thông báo tăng hạng thành viên' },
  { id: ENUM_TYPE_NOTIFICATION.TRACK, name: 'Thông báo cập nhật trạng thái đơn hàng' },
  {
    id: ENUM_TYPE_NOTIFICATION.BIRTHDAY,
    name: 'Thông báo chúc mừng sinh nhật',
  },
  {
    id: ENUM_TYPE_NOTIFICATION.INFORMATION,
    name: 'Thông báo tin tức, sự kiện',
  },
];

export const TRANSLATION_TYPE_NOTIFICATION = {
  [ENUM_TYPE_NOTIFICATION.ADD_POINT]: 'Thông báo tích điểm',
  [ENUM_TYPE_NOTIFICATION.SPEND_POINT]: 'Thông báo đổi quà',
  [ENUM_TYPE_NOTIFICATION.RANK]: 'Thông báo tăng hạng thành viên',
  [ENUM_TYPE_NOTIFICATION.TRACK]: 'Thông báo cập nhật trạng thái đơn hàng',
  [ENUM_TYPE_NOTIFICATION.BIRTHDAY]: 'Thông báo chúc mừng sinh nhật',
  [ENUM_TYPE_NOTIFICATION.INFORMATION]: 'Thông báo tin tức, sự kiện',
};

export const steps = ['Thông báo', 'Người nhận', 'Lên lịch'];

export const typeLink = [
  { label: 'Đường dẫn từ app', value: 'ROUTER' },
  { label: 'Đường dẫn từ web', value: 'DEEP_LINK' },
];

export const DEFAULT_VALUE_NOTIFY_PUSH = {
  name: '',
  title: '',
  detailContent: '',
  templateContent: '',
  routeType: '',
  // routeType: typeLink[0].value,
  deep_link: '',
  route: {
    name: '',
    route: '',
  },
  buttonName: '',
};

export const typeSent = [
  { label: 'Tất cả người dùng', value: ENUM_TYPE_SENT.ALL_USERS },
  {
    label: 'Người dùng theo điều kiện',
    value: ENUM_TYPE_SENT.USER_BY_CONDITION,
  },
];

export const TYPE_BIRTH = [
  { id: ENUM_TYPE_BIRTH.DATE, name: 'Ngày' },
  { id: ENUM_TYPE_BIRTH.MONTH, name: 'Tháng' },
  { id: ENUM_TYPE_BIRTH.YEAR, name: 'Năm' },
  { id: ENUM_TYPE_BIRTH.FULL_DATE, name: 'Ngày/tháng/năm' },
];

export const DEFAULT_VALUE_NOTIFICATION_SCHEDULE = {
  typeTimeSend: null,
  dateTimeFuture: null,
};

export const SCHEDULE_NOTIFICATION = [
  { id: ENUM_SCHEDULE_NOTIFICATION.IMMEDIATE, name: 'Ngay lập tức' },
  { id: ENUM_SCHEDULE_NOTIFICATION.SCHEDULE, name: 'Lên lịch tương lai' },
];

export const TRANSLATION_TYPE_SEND = {
  [ENUM_SCHEDULE_NOTIFICATION.IMMEDIATE]: 'Ngay lập tức',
  [ENUM_SCHEDULE_NOTIFICATION.SCHEDULE]: 'Lên lịch tương lai',
};

export const listStatus = [
  { id: ENUM_STATUS.ACTIVE, name: 'Hoạt động' },
  { id: ENUM_STATUS.INACTIVE, name: 'Ngưng hoạt động' },
];

export const STATUS_ROW = {
  [ENUM_STATUS.ACTIVE]: {
    name: 'Hoạt động',
    color: 'green',
  },
  [ENUM_STATUS.INACTIVE]: {
    name: 'Ngưng hoạt động',
    color: 'red',
  },
};

export const DEFAULT_VALUE_DETAIL_CAMPAIGN: ICampaignByIdForm = {
  title: ' ',
  condition: ' ',
  groupUser: ' ',
  id: 0,
  link: ' ',
  detailContent: ' ',
  templateContent: ' ',
  name: ' ',
  operation: ' ',
  recipientGroup: ' ',
  typeNotify: ' ',
  typeSend: ' ',
  location: ' ',
  lastCheckIn: ' ',
  birthday: ' ',
  scheduleTime: ' ',
  separationCount: ' ',
  timeSent: ' ',
  startDate: ' ',
  endDate: ' ',
  daysOfMonth: ' ',
  daysOfWeek: ' ',
  userId: ' ',
  status: ' ',
  buttonName: '',
  notiTopicId: 0,
};

export const listGender = [
  { value: ENUM_GENDER.MALE, label: 'Nam' },
  { value: ENUM_GENDER.FEMALE, label: 'Nữ' },
];

export const listRank = [
  { id: ENUM_RANK.GOLD, name: ENUM_RANK.GOLD },
  { id: ENUM_RANK.MEMBER, name: ENUM_RANK.MEMBER },
  { id: ENUM_RANK.PLATINUM, name: ENUM_RANK.PLATINUM },
  { id: ENUM_RANK.TITAN, name: ENUM_RANK.TITAN },
];

export const listEvent = [
  { id: '01Plus_2xu', name: '01Plus_2xu' },
  { id: '01Plus_3xu', name: '01Plus_3xu' },
  { id: '01Plus_4xu', name: '01Plus_4xu' },
  { id: '0564023719', name: '0564023719' },
  { id: 'BIRTHDAY_GOLD_2023', name: 'BIRTHDAY_GOLD_2023' },
  { id: 'BIRTHDAY_PLATINUM_2023', name: 'BIRTHDAY_PLATINUM_2023' },
  { id: 'BIRTHDAY_TITAN_2023', name: 'BIRTHDAY_TITAN_2023' },
  { id: 'CBB_BIO_LUOT3', name: 'CBB_BIO_LUOT3' },
  { id: 'CBB_GOLD_LUOT2', name: 'CBB_GOLD_LUOT2' },
  { id: 'CBB_IQ_BIO_LUOT2', name: 'CBB_IQ_BIO_LUOT2' },
  { id: 'CBB_IQ_BIO_LUOT3', name: 'CBB_IQ_BIO_LUOT3' },
  { id: 'CBB_IQ_LUOT3', name: 'CBB_IQ_LUOT3' },
  { id: 'CBB_LUOT2', name: 'CBB_LUOT2' },
  { id: 'CLG_NHAN2', name: 'CLG_NHAN2' },
  { id: 'CLG_NHAN3', name: 'CLG_NHAN3' },
  { id: 'DHA_LUOT1', name: 'DHA_LUOT1' },
  { id: 'Event Quý 4 _ UAT', name: 'Event Quý 4 _ UAT' },
  { id: 'Event Quý 4 Trâm test đừng có xóa', name: 'Event Quý 4 Trâm test đừng có xóa' },
  { id: 'Event_CBB', name: 'Event_CBB' },
  { id: 'EVENT_CBB', name: 'EVENT_CBB' },
  { id: 'EVENT_PIECE', name: 'EVENT_PIECE' },
  { id: 'EVENT_PIECE_RANDOM', name: 'EVENT_PIECE_RANDOM' },
  { id: 'FIRST_SCAN', name: 'FIRST_SCAN' },
  { id: 'FIRST_SCAN_SBP', name: 'FIRST_SCAN_SBP' },
  { id: 'FIRST_SCAN_SBPS', name: 'FIRST_SCAN_SBPS' },
  { id: 'FIRST_SCAN_SMS', name: 'FIRST_SCAN_SMS' },
  { id: 'FIRST_UPGRADE_RANK_GOLD', name: 'FIRST_UPGRADE_RANK_GOLD' },
  { id: 'FIRST_UPGRADE_RANK_PLATIUM', name: 'FIRST_UPGRADE_RANK_PLATIUM' },
  { id: 'GAME', name: 'GAME' },
  { id: 'GAME_CBB', name: 'GAME_CBB' },
  { id: 'GAME_CLS', name: 'GAME_CLS' },
  { id: 'GAME_MUM', name: 'GAME_MUM' },
  { id: 'GAME_W', name: 'GAME_W' },
  { id: 'GIFTING', name: 'GIFTING' },
  { id: 'GIFTING POINT BIRTHDAY', name: 'GIFTING POINT BIRTHDAY' },
  { id: 'INVITE_FIVE_FRIENDS', name: 'INVITE_FIVE_FRIENDS' },
  { id: 'LUCKY_MONEY', name: 'LUCKY_MONEY' },
  { id: 'MUM', name: 'MUM' },
  { id: 'OGGI_NHAN2', name: 'OGGI_NHAN2' },
  { id: 'OGGI_NHAN3', name: 'OGGI_NHAN3' },
  { id: 'OPTI_LUOT1', name: 'OPTI_LUOT1' },
  { id: 'OPTI_LUOT2', name: 'OPTI_LUOT2' },
  { id: 'PROVINCE_GIFT', name: 'PROVINCE_GIFT' },
  { id: 'PROVINCE_GIFT_COLOS_BABY', name: 'PROVINCE_GIFT_COLOS_BABY' },
  { id: 'PROVINCE_GIFT_COLOS_OPTI', name: 'PROVINCE_GIFT_COLOS_OPTI' },
  { id: 'Q1_CBB_1CV', name: 'Q1_CBB_1CV' },
  { id: 'Q1_CBB_50K', name: 'Q1_CBB_50K' },
  { id: 'Q1_CBB_5CV', name: 'Q1_CBB_5CV' },
  { id: 'Q1_CBB_TM1TR', name: 'Q1_CBB_TM1TR' },
  { id: 'Q1_CBB_X2XU', name: 'Q1_CBB_X2XU' },
  { id: 'Q1_CLG_1CV', name: 'Q1_CLG_1CV' },
  { id: 'Q1_CLG_50K', name: 'Q1_CLG_50K' },
  { id: 'Q1_CLG_5CV', name: 'Q1_CLG_5CV' },
  { id: 'Q1_CLG_TM1TR', name: 'Q1_CLG_TM1TR' },
  { id: 'Q1_CLG_X2XU', name: 'Q1_CLG_X2XU' },
  { id: 'Q1_CLK_5CV', name: 'Q1_CLK_5CV' },
  { id: 'Q1_CLK_X2XU', name: 'Q1_CLK_X2XU' },
  { id: 'Q1_CLO_X2XU', name: 'Q1_CLO_X2XU' },
  { id: 'Q1_CLS_50K', name: 'Q1_CLS_50K' },
  { id: 'Q1_CLS_X2XU', name: 'Q1_CLS_X2XU' },
  { id: 'Q1_DHA_5CV', name: 'Q1_DHA_5CV' },
  { id: 'Q1_DHA_X2XU', name: 'Q1_DHA_X2XU' },
  { id: 'Q1_OGGI_1CV', name: 'Q1_OGGI_1CV' },
  { id: 'Q1_OGGI_50K', name: 'Q1_OGGI_50K' },
  { id: 'Q1_OGGI_5CV', name: 'Q1_OGGI_5CV' },
  { id: 'Q1_OGGI_TM1TR', name: 'Q1_OGGI_TM1TR' },
  { id: 'Q1_OGGI_X2XU', name: 'Q1_OGGI_X2XU' },
  { id: 'Q2_2023_CBB_1CV', name: 'Q2_2023_CBB_1CV' },
  { id: 'Q2_2023_CBB_1TR', name: 'Q2_2023_CBB_1TR' },
  { id: 'Q2_2023_CBB_20K', name: 'Q2_2023_CBB_20K' },
  { id: 'Q2_2023_OGGI_20K', name: 'Q2_2023_OGGI_20K' },
  { id: 'Q2_DHA_1_NAM', name: 'Q2_DHA_1_NAM' },
  { id: 'Q2_DHA_20k', name: 'Q2_DHA_20k' },
  { id: 'Q2_DHA_DL', name: 'Q2_DHA_DL' },
  { id: 'Q2_DHA_HN', name: 'Q2_DHA_HN' },
  { id: 'Q2_DHA_SACH', name: 'Q2_DHA_SACH' },
  { id: 'Q3_2023_CLG_NOINAUCHAM', name: 'Q3_2023_CLG_NOINAUCHAM' },
  { id: 'Q3_2023_CLG_PHAOBOI', name: 'Q3_2023_CLG_PHAOBOI' },
  { id: 'Q3_2023_CLG_TUDO', name: 'Q3_2023_CLG_TUDO' },
  { id: 'Q3_2023_CLG_TULANH', name: 'Q3_2023_CLG_TULANH' },
  { id: 'Q3_2023_CLG_X2XU', name: 'Q3_2023_CLG_X2XU' },
  { id: 'Q3_2023_CLS_SUC_KHOE', name: 'Q3_2023_CLS_SUC_KHOE' },
  { id: 'Q3_2023_CLS_TAM_SOAT', name: 'Q3_2023_CLS_TAM_SOAT' },
  { id: 'Q3_2023_CLS_X2XU', name: 'Q3_2023_CLS_X2XU' },
  { id: 'Q3_2023_DHA_DHA_1 LON DHA 400G 0+', name: 'Q3_2023_DHA_DHA_1 LON DHA 400G 0+' },
  { id: 'Q3_2023_DHA_DHA_1 LON DHA 400G 1+', name: 'Q3_2023_DHA_DHA_1 LON DHA 400G 1+' },
  { id: 'Q3_2023_OGGI_IPAD', name: 'Q3_2023_OGGI_IPAD' },
  { id: 'Q3_2023_OGGI_LY', name: 'Q3_2023_OGGI_LY' },
  { id: 'Q3_2023_OGGI_VALI', name: 'Q3_2023_OGGI_VALI' },
  { id: 'Q3_2023_OGGI_X2XU', name: 'Q3_2023_OGGI_X2XU' },
  { id: 'Q3_2023_OGGI_XE_DAP', name: 'Q3_2023_OGGI_XE_DAP' },
  { id: 'Q3_2023_OPTI_1 LON OPTI 400G 0+', name: 'Q3_2023_OPTI_1 LON OPTI 400G 0+' },
  { id: 'Q3_CBB_DL', name: 'Q3_CBB_DL' },
  { id: 'Q3_CBB_IP14', name: 'Q3_CBB_IP14' },
  { id: 'Q3_CBB_TM100', name: 'Q3_CBB_TM100' },
  { id: 'Q3_CBB_TM50', name: 'Q3_CBB_TM50' },
  { id: 'Q3_CBB_X2Xu', name: 'Q3_CBB_X2Xu' },
  { id: 'Q4_2023_CLS_TAM_SOAT', name: 'Q4_2023_CLS_TAM_SOAT' },
  { id: 'Q4_CBB', name: 'Q4_CBB' },
  { id: 'Q4_CBB_GNhi', name: 'Q4_CBB_GNhi' },
  { id: 'Q4_CBB_IPHONE_14', name: 'Q4_CBB_IPHONE_14' },
  { id: 'Q4_CLG_GDB', name: 'Q4_CLG_GDB' },
  { id: 'Q4_CLG_GKK', name: 'Q4_CLG_GKK' },
  { id: 'Q4_CLK_GDB', name: 'Q4_CLK_GDB' },
  { id: 'Q4_CLK_GKK', name: 'Q4_CLK_GKK' },
  { id: 'Q4_CLK_GNhi', name: 'Q4_CLK_GNhi' },
  { id: 'Q4_CLO_GKK', name: 'Q4_CLO_GKK' },
  { id: 'Q4_CLO_GNhat', name: 'Q4_CLO_GNhat' },
  { id: 'Q4_CLO_GNhi', name: 'Q4_CLO_GNhi' },
  { id: 'Q4_CLS_GKK', name: 'Q4_CLS_GKK' },
  { id: 'Q4_CLS_GNhat', name: 'Q4_CLS_GNhat' },
  { id: 'Q4_DHA_GKK', name: 'Q4_DHA_GKK' },
  { id: 'Q4_OGGI_GDB', name: 'Q4_OGGI_GDB' },
  { id: 'Q4_OGGI_GKK', name: 'Q4_OGGI_GKK' },
  { id: 'Q4_OGGI_GNhat', name: 'Q4_OGGI_GNhat' },
  { id: 'Q4_OGGI_GNhi', name: 'Q4_OGGI_GNhi' },
  { id: 'QR_CODE', name: 'QR_CODE' },
  { id: 'QR_CODE_SBPS', name: 'QR_CODE_SBPS' },
  { id: 'QR_CODE_SMS', name: 'QR_CODE_SMS' },
  { id: 'QR_CODE_TIER_GOLD_2023', name: 'QR_CODE_TIER_GOLD_2023' },
  { id: 'QR_CODE_TIER_PLATINUM_2023', name: 'QR_CODE_TIER_PLATINUM_2023' },
  { id: 'QR_CODE_TIER_TITAN_2023', name: 'QR_CODE_TIER_TITAN_2023' },
  { id: 'QUY4_DHA_BI_KIP_THONG_MINH', name: 'QUY4_DHA_BI_KIP_THONG_MINH' },
  { id: 'RESET_POINT', name: 'RESET_POINT' },
  { id: 'RESET_POINT_365_2023', name: 'RESET_POINT_365_2023' },
  { id: 'RESET_TIER', name: 'RESET_TIER' },
  { id: 'RESET_TIER_365_2023', name: 'RESET_TIER_365_2023' },
  { id: 'RESET_TIER_GOLD_180_2023', name: 'RESET_TIER_GOLD_180_2023' },
  { id: 'RESET_TIER_TITAN_180_2023', name: 'RESET_TIER_TITAN_180_2023' },
  { id: 'RETURN_POINT', name: 'RETURN_POINT' },
  { id: 'REWARD_GIFT', name: 'REWARD_GIFT' },
  { id: 'SPECIAL_POINT', name: 'SPECIAL_POINT' },
  { id: 'TEST', name: 'TEST' },
  { id: 'TIER_UP', name: 'TIER_UP' },
  { id: 'TIER_UP_GOLD_2023', name: 'TIER_UP_GOLD_2023' },
  { id: 'TIER_UP_PLATINUM_2023', name: 'TIER_UP_PLATINUM_2023' },
];

export const validTypeImage = ['jpeg', 'jpg', 'png'];

export const validTypeJson = ['json'];
