import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITag } from './interface';
import { RootState } from 'src/common/redux/store';

const initialValue: { tags: ITag[] } = {
  tags: [
    {
      id: 0,
      tag: '',
      priority: 0,
      action: '',
      status: false,
      createdDate: new Date(),
      updatedDate: new Date(),
    },
  ],
};

const feedbackTagManage = createSlice({
  name: 'tags',
  initialState: initialValue,
  reducers: {
    setTags: (state, action: PayloadAction<ITag[]>) => {
      state.tags = action.payload;
    },
  },
});

export default feedbackTagManage.reducer;
export const tagsSelector = (state: RootState) => state.feedbackTagManage.tags;
export const { setTags } = feedbackTagManage.actions;
