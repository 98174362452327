import {
  API_ADMIN,
  API_HISTORY_EDIT_ADMIN,
  API_MODUL,
  API_PERMISSION,
  API_PERMISSIONS,
} from 'src/common/constants/apis';
import axiosInstance from 'src/common/utils/axios';
import {
  IResAdmins,
  IAdminParams,
  IFormAdmin,
  IApiResponse,
  IResModule,
  IFormPermission,
  IFormAdminCreate,
  IResAdminEditHistory,
  IEditAdminParams,
  IResPermission,
} from './interfaces';

export const getAdmin = async (params: IAdminParams) => {
  return axiosInstance.get<unknown, IResAdmins>(`${API_ADMIN}`, { params });
};

export const getPermission = async (accountId: number) => {
  return axiosInstance.get<unknown, IApiResponse>(`${API_PERMISSION}`, {
    params: {
      accountId: accountId,
    },
  });
};

export const getPermissions = async () => {
  return axiosInstance.get<unknown, IResPermission>(`${API_PERMISSIONS}`);
};

export const getModule = async () => {
  return axiosInstance.get<unknown, IResModule>(`${API_MODUL}`);
};

export const getHistory = async (params: IAdminParams) => {
  return axiosInstance.get<unknown, IResAdminEditHistory>(`${API_HISTORY_EDIT_ADMIN}`, {
    params,
  });
};

export const getExportData = async (params: IEditAdminParams) => {
  try {
    const response = await axiosInstance.get(`${API_HISTORY_EDIT_ADMIN}/export`, {
      params,
      responseType: 'blob', // Nhận dữ liệu dạng file
    });

 
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'admin-action-history.csv'); // Đặt tên file CSV
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Lỗi khi export dữ liệu:', error);
  }
};

export const deleteAdmin = (id: number) => {
  const data = axiosInstance.delete(`${API_ADMIN}/${id}`, { data: { id: id } });
  return data;
};

export const addAllNewAccount = (data: IFormAdminCreate) => {
  return axiosInstance.post(API_ADMIN, data);
};

export const addPermissionForAccount = (data: IFormPermission) => {
  return axiosInstance.post(API_PERMISSION, data);
};

export const getAdminById = (id: number) => axiosInstance.get(`${API_ADMIN}/${id}`);
export const editAdmin = ({ data, id }: { data: IFormAdmin; id: number }) =>
  axiosInstance.put(`${API_ADMIN}/${id}`, data);
