import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IParamsGetUserList } from './interface';
import { DEFAULT_VALUE_PARAMS_GET_USER_LIST } from './constant';

const initialValue: {
  paramsGetUserList: IParamsGetUserList;
  openModalUpdateStatusLogin: boolean;
  openModalUpdateStatusDefaultAddress: boolean;
  dataUpdateStatusLogin?: {
    userId: number;
    blockLoginAccount: boolean;
  };
  dataUpdateStatusDefaultAddress?: {
    userId: number;
    blockUpdateDefaultAddress: boolean;
  };
} = {
  paramsGetUserList: DEFAULT_VALUE_PARAMS_GET_USER_LIST,
  openModalUpdateStatusLogin: false,
  openModalUpdateStatusDefaultAddress: false,
  dataUpdateStatusLogin: undefined,
  dataUpdateStatusDefaultAddress: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialValue,
  reducers: {
    setParamsGetUserList: (state, action: PayloadAction<IParamsGetUserList>) => {
      state.paramsGetUserList = action.payload;
    },
    setOpenModalUpdateStatusLogin: (state, action: PayloadAction<boolean>) => {
      state.openModalUpdateStatusLogin = action.payload;
    },
    setOpenModalUpdateStatusDefaultAddress: (state, action: PayloadAction<boolean>) => {
      state.openModalUpdateStatusDefaultAddress = action.payload;
    },
    setDataUpdateStatusLogin: (
      state,
      action: PayloadAction<{
        userId: number;
        blockLoginAccount: boolean;
      }>
    ) => {
      state.dataUpdateStatusLogin = action.payload;
    },
    setDataUpdateStatusDefaultAddress: (
      state,
      action: PayloadAction<{
        userId: number;
        blockUpdateDefaultAddress: boolean;
      }>
    ) => {
      state.dataUpdateStatusDefaultAddress = action.payload;
    },
  },
});

export default userSlice.reducer;

export const {
  setParamsGetUserList,
  setOpenModalUpdateStatusLogin,
  setDataUpdateStatusLogin,
  setOpenModalUpdateStatusDefaultAddress,
  setDataUpdateStatusDefaultAddress,
} = userSlice.actions;
