import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStateProps } from './interfaces';
import { EventCommonType } from './enums';

const initialValue: IStateProps = {
  updateData: {
    eventId: 0,
    eventType: EventCommonType.EVENT,
    priority: 1,
  },
  isOpenFormUpdate: false,
};

const EventCommonPopupPrioritySlice = createSlice({
  name: 'EventCommonPopupPriority',
  initialState: initialValue,
  reducers: {
    setUpdateData: (state, action: PayloadAction<any>) => {
      state.updateData = action.payload;
    },
    setIsOpenFormUpdate: (state, action: PayloadAction<boolean>) => {
      state.isOpenFormUpdate = action.payload;
    },
  },
});

export default EventCommonPopupPrioritySlice.reducer;

export const { setUpdateData, setIsOpenFormUpdate } =
  EventCommonPopupPrioritySlice.actions;
